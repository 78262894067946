import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react-pro'
import React from 'react'
import { useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import { useNavigate, useParams } from 'react-router-dom'
import BasicList from 'src/components/BasicList'
import listFields from './ProductList'
import GetImage from 'src/components/GetImage'
import EditButton from 'src/components/EditButton'
import PriceDistributionTable from './PriceDistributionTable'
import { formatPriceToDisplayDecimals } from 'src/helpers'
import IsLoading from 'src/components/IsLoading'

const Product = () => {
  const { id } = useParams()

  const { data, isError, error, isLoading, isRefetching } = useQuery(
    ['product', id],
    DataService.products.getProduct(id)
  )

  const navigate = useNavigate()
  // console.log(data_Cover)

  if (isLoading) return <IsLoading />
  if (isError) {
    console.log(error)
    return <span>błąd</span>
  }

  // useEffect(() => {
  //   if (!isLoading_Cover) console.log(data_Cover)
  // }, [data_Cover])

  return (
    <>
      <CContainer fluid className="h-100">
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>{data?.contractorDetails?.companyName}</h2>
            <div>
              <EditButton label="Edytuj" onClick={() => navigate(`/produkt/${id}/edit`)} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs={4}>
            <CCard>
              <CCardHeader>Dane szczegółowe</CCardHeader>
              <CRow>
                <div className="mx-auto w-auto mt-3" style={{ height: '200px' }}>
                  {!isLoading && !isRefetching && (
                    <GetImage id={['product', id, 'cover']} path={data.file} />
                  )}
                </div>
              </CRow>
              <BasicList listFields={listFields} data={data} />
            </CCard>
          </CCol>
          <CCol xs={8}>
            <CRow className="pb-4">
              <CCol>
                <CCard
                  className="pt-2 px-3 h-100 thunderBg flex-column justify-content-between"
                  style={{ maxHeight: '100px' }}
                >
                  <span className="fs-6">Liczba kodów na stanie</span>
                  <br />
                  <span className="fs-1 ">{data?.amount}</span>
                </CCard>
              </CCol>
              <CCol>
                <CCard
                  className="pt-2 px-3 h-100 thunderBg flex-column justify-content-between"
                  style={{ maxHeight: '100px' }}
                  // className="pt-2 px-3 h-100"
                >
                  <span className="fs-6">Uśredniona cena sprzedaży </span>
                  <br />
                  <span className="fs-1 ">
                    {formatPriceToDisplayDecimals(data?.meanPrice).toFixed(2) +
                      ' ' +
                      (data?.currency ? data?.currency : 'EUR')}
                  </span>
                </CCard>
              </CCol>
            </CRow>
            <CCard>
              <CCardHeader>ROZKŁAD CEN</CCardHeader>
              <CCardBody>
                <PriceDistributionTable id={id} />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default Product
