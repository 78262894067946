import React, { useRef } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from '@coreui/react-pro'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import BasicForm from 'src/components/BasicForm'
import formFields from './OrderForm'
import DataService from 'src/services/DataService'
import { notifyError } from 'src/services/notify'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import { useState } from 'react'
import EditButton from 'src/components/EditButton'
import CancelButton from 'src/components/CancelButton'
import AddOrderProductWrapper from 'src/components/AddOrderProductWrapper'
import { convertPriceToWholeNumber } from 'src/helpers'
import formFields2 from './OrderForm2'

const OrderNew = () => {
  // const [deliveryContainerId, setDeliveryContainerId] = useState()

  let orderIdRef = useRef()

  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isDisabled, _] = useState(false)

  const { register, unregister, watch, handleSubmit, setValue } = useForm({
    defaultValues: {
      contractor: '',
      generateInvoice: true, //flag to create invoice for every new order
      invoiceNumber: '',
      dateOfOrder: '',
      status: 'NOWE',
      isPaid: '',
      products: [], // array of products objects with codes and files and productId)
      note: '',
    },
  })

  const navigate = useNavigate()

  const { mutate: addOrderMutation } = useMutation(DataService.orders.addOrder(), {
    onSuccess: ({ res, orderId }) => {
      console.log('saving order succes (recive orderID)', res.data)
      orderIdRef.current = orderId
      navigate(`/zamowienie/${res.data.id}`)
    },
    onError: (res) => {
      notifyError('Dodawanie dostawy zakończyło się niepowodzeniem')
      console.log('Creating delivery falied ', res)
      setIsLoading(false)
    },
  })

  const onSubmitSave = async (data) => {
    setIsLoading(true)
    console.log('@Data before transformation AddOrder', data)
    //flaten structure of added codes from deliveries
    const formatedData = { ...data }
    formatedData.products = []
    for (let product of data.products) {
      formatedData.products.push({
        id: product.id,
        price: convertPriceToWholeNumber(product.price),
        currency: 'EUR',
        deliveryProducts: [
          ...product.addingCodes.filter(
            (el) => el.amountInTXT > 0 || el.sumOfSelectedCodesInFiles > 0
          ),
        ],
        expectedQuantity: product.expectedQuantity,
        addedCodesSum: product.addedCodesSum,
      })
    }
    //check if at least on product is added
    if (formatedData.products.length === 0) {
      notifyError('Dodaj przynajmniej jeden produkt do zamówienia!')
      setIsLoading(false)
      return
    }
    //check if expectedQuantity >= addedCodesSum
    console.log(formatedData)

    let shouldExit = false
    formatedData.products.forEach((product) => {
      console.log(product)
      if (product.addedCodesSum > product.expectedQuantity) {
        notifyError(`Błąd! Dodano więcej kodów niż oczekiwano (${product.name})`)
        shouldExit = true
        return
      }
    })
    if (shouldExit) {
      setIsLoading(false)
      return
    }
    addOrderMutation(formatedData)
  }

  return (
    <>
      <CContainer fluid className="h-100">
        {isLoading && <IsLoadingOverlay />}
        <CRow>
          <CCol className="d-flex flex-row justify-content-between pb-4">
            <h2>Tworzenie nowego zamówienia</h2>

            <div>
              <EditButton label="Zapisz" />
              <CancelButton label="Anuluj" onClick={() => navigate('/zamowienia')} />
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg={3}>
            <CCard>
              <CCardHeader>Dane Zamówienia</CCardHeader>
              <CCardBody>
                <CRow className="mb-3">
                  <BasicForm
                    formFields={formFields}
                    onSubmit={handleSubmit((data) => onSubmitSave(data))}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    disabled={isDisabled}
                    className="mb-3"
                  />
                  {!watch().generateInvoice && (
                    <CForm>
                      <CFormLabel className="mb-3 mt-0" htmlFor={'invoiceNumber'}>
                        Numer faktury
                      </CFormLabel>
                      <CRow>
                        <CCol>
                          <CFormInput
                            type={'text'}
                            id={'invoiceNumber'}
                            placeholder={'np. FV123'}
                            {...register(`invoiceNumber`)}
                            className="mb-3 fs-5"
                            disabled={isDisabled}
                          />
                        </CCol>
                      </CRow>
                    </CForm>
                  )}
                  <BasicForm
                    formFields={formFields2}
                    onSubmit={handleSubmit((data) => onSubmitSave(data))}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    disabled={isDisabled}
                    className="mb-3"
                  />
                </CRow>
                <CRow className="px-3">
                  <CButton
                    color="primary"
                    variant="outline"
                    onClick={() => navigate('/kontrahent/new')}
                  >
                    Dodaj Zamawiającego
                  </CButton>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol lg={9}>
            <CCard className="bg-transparent">
              <CCardBody className="px-0 py-2">
                <AddOrderProductWrapper
                  register={register}
                  unregister={unregister}
                  watch={watch}
                  setValue={setValue}
                  disabled={isDisabled}
                />
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}
export default OrderNew
