import { CCardBody, CCollapse } from '@coreui/react-pro'
import React from 'react'
import { navigationStylingProps } from 'src/assets/custom/tableSorting'
import { ReactComponent as ChevRight } from 'src/assets/custom/chevron-right.svg'
import { ReactComponent as ChevDown } from 'src/assets/custom/chevron-down.svg'
import { formatPriceToDisplayDecimals } from 'src/helpers'
import FillBar from 'src/components/FillBar'
import OrderProductDeliveriesDetails from 'src/components/OrderProductDeliveriesDetails'

const columns = [
  {
    key: 'name',
    label: 'Produkt',
    _style: { minWidth: '160px' },
  },
  {
    key: 'expectedQuantity',
    label: 'Ilość',
    _style: { minWidth: '80px' },
  },
  {
    key: 'priceByCurrency',
    label: 'Cena sprzedaży w walucie',
    _style: { minWidth: '50px' },
  },
  {
    key: 'price',
    label: 'Cena sprzedaży',
    _style: { minWidth: '120px' },
  },
  {
    key: 'format',
    label: 'Format',
    _style: { minWidth: '100px' },
  },
  {
    key: 'sent',
    label: 'Dodano',
    _style: { minWidth: '130px' },
  },
  {
    key: 'value',
    label: 'Wartość',
    _style: { minWidth: '100px' },
  },
]

const toggleDetails = (index, details, setDetails) => {
  const position = details.indexOf(index)
  let newDetails = details.slice()
  if (position !== -1) {
    newDetails.splice(position, 1)
  } else {
    newDetails = [...details, index]
  }
  setDetails(newDetails)
}

export const tableProps = (navigate = null, details, setDetails) => {
  return {
    columns: columns,
    scopedColumns: {
      name: (product) => {
        return (
          <td className="d-flex flex-row align-items-center m-0 p-0 border-0">
            {product.codes > 0 ? (
              <>
                <div
                  className="d-flex flex-row align-items-center justify-content-center h-100"
                  style={{ width: '40px', backgroundColor: '#323c52', minHeight: '45px' }}
                >
                  {!details.includes(product.name) ? (
                    <ChevRight
                      className="btn p-0 m-0 shadow-none "
                      style={{ fill: '#fcd002', width: '20px', height: '20px' }}
                      onClick={() => {
                        toggleDetails(product.name, details, setDetails)
                      }}
                    />
                  ) : (
                    <ChevDown
                      className="btn p-0 m-0 shadow-none"
                      style={{ fill: '#fcd002', width: '20px', height: '20px' }}
                      onClick={() => {
                        toggleDetails(product.name, details, setDetails)
                      }}
                    />
                  )}
                </div>
                <div className="py-2 ms-3">{product.name}</div>
              </>
            ) : (
              <div style={{ padding: '12px' }}>{product.name}</div>
            )}
          </td>
        )
      },
      price: ({ currency, price }) => {
        return <td>{formatPriceToDisplayDecimals(price) + ' EUR'}</td>
      },
      sent: ({ codes, expectedQuantity }) => {
        return (
          <td>
            <FillBar value={codes} sum={expectedQuantity} />
          </td>
        )
      },
      value: ({ price, expectedQuantity, currency }) => {
        return (
          <td>
            {formatPriceToDisplayDecimals(price * expectedQuantity) + ' ' + (currency || 'EUR')}
          </td>
        )
      },
      priceByCurrency: ({ priceByCurrency, currency }) => {
        return <td>{formatPriceToDisplayDecimals(priceByCurrency) + ' ' + currency}</td>
      },
      details: (product) => {
        return (
          <CCollapse visible={details.includes(product.name)}>
            <CCardBody className="p-3">
              <h6>Szczegóły kodów:</h6>
              <OrderProductDeliveriesDetails data={product.deliveries} />
            </CCardBody>
          </CCollapse>
        )
      },
    },

    clickableRows: false,
    columnSorter: true,
    pagination: true,
    itemsPerPageSelect: false,
    itemsPerPage: 100,
    tableProps: {
      striped: true,
      hover: true,
    },
    ...navigationStylingProps,
  }
}
