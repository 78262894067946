import React from 'react'
import { CBadge } from '@coreui/react-pro'

const Badge = ({ status, module }) => {
  const getBadgeColor = (module, status) => {
    switch (module) {
      case 'complaint':
        switch (status) {
          case 'OTWARTE':
            return 'danger'
          case 'ZAMKNIĘTE':
            return 'dark'
          case 'W TRAKCIE':
            return 'secondary'
          case 'ROZWIĄZANE':
            return 'success'
          default:
            return ''
        }
      case 'delivery':
        switch (status) {
          case 'NOWE':
            return 'secondary'
          case 'OTRZYMANE':
            return 'success'
          case 'ANULOWANE':
            return 'danger'
          default:
            return ''
        }
      case 'order':
        switch (status) {
          case 'NOWE':
            return 'secondary'
          case 'WYSŁANE':
            return 'success'
          case 'ANULOWANE':
            return 'danger'
          default:
            return ''
        }
      case 'isPaid':
        switch (status) {
          case false:
            return 'danger'
          case true:
            return 'success'
          default:
            return 'dark'
        }
      case 'offer':
        switch (status) {
          case 'OCZEKUJĄCE':
            return 'secondary'
          case 'POTWIERDZONE':
            return 'success'
          case 'ANULOWANE':
            return 'danger'
          default:
            return ''
        }
      case 'daily':
        switch (status) {
          case 'AKTUALNE':
            return 'success'
          case 'ARCHIWALNE':
            return 'dark'
          case 'STAŁE':
            return 'secondary'
          default:
            return ''
        }
      case 'sold':
        switch (status) {
          case 'sprzedane':
            return 'success'
          case 'dostępne':
            return 'secondary'
          default:
            return ''
        }
      default:
        return ''
    }
  }
  return (
    <CBadge color={getBadgeColor(module, status)}>
      <p className="inline m-0" style={{ padding: '0', lineHeight: '1', fontSize: '11px' }}>
        {typeof status === 'boolean' ? (status === true ? 'TAK' : 'NIE') : status}
      </p>
    </CBadge>
  )
}
export default Badge
