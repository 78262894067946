import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCard,
  CFormInput,
  CFormLabel,
} from '@coreui/react-pro'
import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilTrash } from '@coreui/icons'
import OrderRecords from './OrderRecords'
import { useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import IsLoading from './IsLoading'
import { useEffect } from 'react'

const AddOrderProduct = ({
  // product, //full info abut products and codes after fetch
  index, //as a position in array
  register,
  watch,
  setValue,
  disabled: editing,
  reset,
}) => {
  let product = watch().products[index]

  // console.log('product in AddOrderPorduct', product)

  const currency = watch().currency

  const handleProductRemove = () => {
    console.log(watch().products)
    watch().products.splice(index, 1)
    reset()
  }

  const { data, isLoading, isError } = useQuery(
    ['product', product.id, 'deliveries'],
    DataService.orders.getProductDeliveries(product.id)
  )

  useEffect(() => {
    setValue(`products[${index}].addingCodes`, [])
    // console.log('adding field for added Codes, shoud be empty array', watch())
    if (data?.length > 0) {
      data.forEach((delivery, id) => {
        setValue(`products[${index}].addingCodes[${id}].id`, delivery.deliveryId)
      })
    }
  }, [data, index, setValue, editing, watch])

  const countSum = () => {
    let sum = 0
    if (watch().products[index]?.addingCodes) {
      const addingCodes = watch().products[index].addingCodes
      for (const code of Object.keys(addingCodes)) {
        if (addingCodes[code].amountInTXT) sum += Number(addingCodes[code].amountInTXT)
        if (addingCodes[code].sumOfSelectedCodesInFiles)
          sum += Number(addingCodes[code].sumOfSelectedCodesInFiles)
      }
    }
    return sum
  }

  return (
    <>
      <CCard className="mb-5" key={index}>
        <CAccordion activeItemKey={''} flush>
          <CAccordionItem className=" ">
            <div className="flex-row-reverse d-flex justify-content-between align-items-center">
              <div className="pe-5 d-flex align-items-center">
                <CFormLabel className="p-0 m-0 me-2">Cena {currency}:</CFormLabel>
                <CFormInput
                  type="number"
                  id={`${index}_price`}
                  placeholder={'Cena'}
                  {...register(`products.${index}.price`, {
                    required: true,
                    valueAsNumber: true,
                  })}
                  className="fs-5 me-3"
                  disabled={editing}
                  step={0.01}
                  style={{ maxWidth: '80px', maxHeight: '30px' }}
                  min={0}
                  required={true}
                />
                <CFormLabel className="p-0 m-0 me-2">Ilość:</CFormLabel>
                <CFormInput
                  type="number"
                  id={`${index}_price`}
                  placeholder={'Ilość'}
                  {...register(`products.${index}.expectedQuantity`, {
                    required: true,
                    valueAsNumber: true,
                  })}
                  className="fs-5 me-3"
                  disabled={editing}
                  step={1}
                  style={{ maxWidth: '80px', maxHeight: '30px' }}
                  min={
                    watch().products[index].alreadyAddedCodes
                      ? watch().products[index].alreadyAddedCodes
                      : 0
                  }
                  required={true}
                />
                {/* countSum nie powinno być tu liczone tylko jako wartość z góry */}
                <CFormLabel className="p-0 m-0">
                  Ilość dodana: {}
                  {(countSum() || 0) + (product?.codes || 0)}
                </CFormLabel>
                {/* {console.log(product)} */}
              </div>
              <CAccordionHeader
                className="bg-transparent d-block"
                style={{ maxWidth: '300px', minWidth: '250px' }}
              >
                {!editing && (
                  <div
                    onClick={handleProductRemove}
                    style={{
                      width: '20px',
                      height: '20px',
                      border: 'none',
                      boxShadow: 'none',
                    }}
                  >
                    <CIcon
                      icon={cilTrash}
                      className="text-danger"
                      style={{ width: '20px', height: '20px' }}
                    />
                  </div>
                )}
                <div className="px-3">{product.name}</div>
              </CAccordionHeader>
            </div>
            <CAccordionBody className="pt-0">
              {isError && 'błąd'}
              {isLoading && <IsLoading />}
              {!isError && !isLoading && data && (
                <>
                  <p className="text-white">Dostępne kody:</p>
                  <OrderRecords
                    index={index}
                    data={data.deliveryProducts}
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    defaultCodesInFile={product.codes_in_photo}
                  />
                </>
              )}
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CCard>
    </>
  )
}

export default AddOrderProduct
