import { CAccordion, CAccordionItem, CCard, CFormInput, CFormLabel } from '@coreui/react-pro'
import React from 'react'
import { useQuery } from 'react-query'
import DataService from 'src/services/DataService'
import { useEffect } from 'react'

const EditOrderProduct = ({
  // product, //full info abut products and codes after fetch
  index, //as a position in array
  register,
  watch,
  setValue,
  disabled: editing,
  reset,
}) => {
  let product = watch().products[index]

  // console.log('product in AddOrderPorduct', product)

  const { data } = useQuery(
    ['product', product.id, 'deliveries'],
    DataService.orders.getProductDeliveries(product.id)
  )

  useEffect(() => {
    setValue(`products[${index}].addingCodes`, [])
    // console.log('adding field for added Codes, shoud be empty array', watch())
    if (data?.length > 0) {
      data.forEach((delivery, id) => {
        setValue(`products[${index}].addingCodes[${id}].id`, delivery.deliveryId)
      })
    }
  }, [data, index, setValue, editing, watch])

  return (
    <>
      <CCard className="mb-5" key={index}>
        <CAccordion activeItemKey={''} flush>
          <CAccordionItem className=" ">
            <div className="flex-row-reverse d-flex justify-content-between align-items-center">
              <div className="pe-5 d-flex align-items-center">
                <CFormLabel className="p-0 m-0 me-2">Cena {watch().currency}:</CFormLabel>
                <CFormInput
                  type="number"
                  id={`${index}_price`}
                  placeholder={'Cena'}
                  {...register(`products.${index}.price`, {
                    required: true,
                    valueAsNumber: true,
                  })}
                  className="fs-5 me-3"
                  disabled={editing}
                  step={0.01}
                  style={{ maxWidth: '80px', maxHeight: '30px' }}
                  min={0}
                  required={true}
                />
                <CFormLabel className="p-0 m-0 me-2">Ilość:</CFormLabel>
                <CFormInput
                  type="number"
                  id={`${index}_price`}
                  placeholder={'Ilość'}
                  {...register(`products.${index}.expectedQuantity`, {
                    required: true,
                    valueAsNumber: true,
                  })}
                  className="fs-5 me-3"
                  disabled={editing}
                  step={1}
                  style={{ maxWidth: '80px', maxHeight: '30px' }}
                  min={
                    watch().products[index].alreadyAddedCodes
                      ? watch().products[index].alreadyAddedCodes
                      : 0
                  }
                  required={true}
                />
              </div>
              <div
                className="bg-transparent"
                style={{
                  maxWidth: '300px',
                  minWidth: '250px',
                  height: '50px',
                  display: 'flex',
                  // justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className="px-3">{product.name}</div>
              </div>
            </div>
          </CAccordionItem>
        </CAccordion>
      </CCard>
    </>
  )
}

export default EditOrderProduct
