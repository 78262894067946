import { CListGroup, CListGroupItem } from '@coreui/react-pro'
import React, { lazy } from 'react'
import { formatData, generateGameStormID } from 'src/helpers'
import Badge from './Badge'
const getValue = (record, data) => {
  if (data?.[record.category]?.[record.subcategory]?.[record.id] !== undefined)
    return formatData(data[record.category][record.subcategory][record.id], record.format)
  if (data?.[record.category]?.[record.id] !== undefined)
    return formatData(data[record.category][record.id], record.format)
  if (data?.[record.id] === null) return '-'
  if (data?.[record.id] !== undefined) return formatData(data[record.id], record.format)
  if (record.placeholder !== undefined) return record.placeholder
  return '-'
}

const AvailableForSaleSwitch = lazy(() => import('./Switches/AvailableForSaleSwitch'))

const BasicList = ({ listFields, data }) => {
  // console.log(listFields)
  return (
    <CListGroup flush className="p-2">
      <CListGroupItem className="fs-6">
        {listFields.map((field, id) => (
          <div key={id} className="d-flex justify-content-between fs-6 pb-3 align-items-end">
            {field.id ? (
              <>
                {/* {field.id === 'inStock' && console.log(field, data)} */}
                <span className="fs-6 fw-light">{field.label}&nbsp;</span>
                {field?.component?.type === 'gameStormId' ? (
                  <span className="text-uppercase fw-semibold">
                    {generateGameStormID(field?.component?.param, getValue(field, data))}
                  </span>
                ) : field?.component?.type === 'badge' ? (
                  <h5>
                    <Badge module={field.component.param} status={getValue(field, data)} />
                  </h5>
                ) : field?.component?.type === 'component' ? (
                  <div style={{ transform: 'translateX(10px)' }}>
                    <AvailableForSaleSwitch value={getValue(field, data)} />
                  </div>
                ) : (
                  <span className="text-uppercase fw-semibold text-truncate">
                    {getValue(field, data)} {field.suffix}
                  </span>
                )}
              </>
            ) : (
              <br key={id} />
            )}
          </div>
        ))}
      </CListGroupItem>
    </CListGroup>
  )
}
export default BasicList
