import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CSmartTable,
  CTabContent,
  CTabPane,
} from '@coreui/react-pro'
import React, { useEffect, useRef } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { tableProps } from './ProductsTable'
import { useNavigate } from 'react-router-dom'
import DataService from 'src/services/DataService'
import { useState } from 'react'
import EditButton from 'src/components/EditButton'
import IsLoading from 'src/components/IsLoading'
import DisplayNews from 'src/components/DisplayNews'
import { useStoreState } from 'easy-peasy'
import { useForm } from 'react-hook-form'
import { paginationProps } from 'src/assets/custom/tableSorting'
import IsLoadingOverlay from 'src/components/IsLoadingOverlay'
import StoreValue from 'src/components/StoreValue'
import { downloadCsvFile } from 'src/helpers'

const initFilters = {
  name: '',
  amountFrom: '',
  amountTo: '',
  meanPriceFrom: '',
  meanPriceTo: '',
  totalFrom: '',
  totalTo: '',
  valueFrom: '',
  valueTo: '',
}

const Products = () => {
  const navigate = useNavigate()
  const isAdmin = useStoreState((store) => store.auth.isAdmin)
  const [activeKey, setActiveKey] = useState(1)
  const [filter, setFilter] = useState(initFilters)
  const [prevVals, setPrevVals] = useState(initFilters)

  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState()
  const [sortDir, setSortDir] = useState()
  const [type, setType] = useState()
  const isMountedRef = useRef(false)

  const { register, watch } = useForm({
    defaultValues: initFilters,
  })

  const { data, isLoading, refetch, isRefetching, isPreviousData } = useQuery(
    ['products', filter, itemsPerPage, activePage, type],
    DataService.products.getProducts({
      ...filter,
      perPage: itemsPerPage,
      page: activePage,
      sortBy: sortBy,
      sortDir: sortDir,
      type: type,
    }),
    { keepPreviousData: true }
  )

  const { mutate: downloadCsvMutation } = useMutation(
    DataService.products.getProductsCsv({
      ...filter,
      perPage: itemsPerPage,
      page: activePage,
      sortBy: sortBy,
      sortDir: sortDir,
      type: type,
    }),
    {
      onSuccess: (res) => {
        downloadCsvFile(res.data, 'products.csv')
      },
    }
  )

  const currVals = watch()

  useEffect(() => {
    if (JSON.stringify(currVals) !== JSON.stringify(prevVals)) {
      setPrevVals({ ...currVals })
      setFilter(currVals)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currVals, prevVals])

  const timeoutRef = useRef()
  const queryClient = useQueryClient()

  useEffect(() => {
    // check if data is present from first req and mark that as mounted
    if (data && isMountedRef.current === false) {
      isMountedRef.current = true
    }
  }, [data])

  useEffect(() => {
    // check if is mounted before trying to refetch
    if (isMountedRef.current === false) {
      return
    }
    if (isRefetching)
      queryClient.cancelQueries({
        queryKey: ['products', filter, itemsPerPage, activePage, sortBy, sortDir, type],
      })

    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(() => {
      refetch()
    }, 500)

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current)
    }
    // eslint-disable-next-line
  }, [filter])

  //for sorting and pagination change
  useEffect(() => {
    // check if is mounted before trying to refetch
    if (isMountedRef.current === false || isLoading) {
      return
    }
    // refetch()
    // eslint-disable-next-line
  }, [sortBy, sortDir, activePage, itemsPerPage])

  const handleDownloadCSV = () => {
    downloadCsvMutation()
  }

  if (isLoading) return <IsLoading />

  return (
    <>
      <CContainer fluid>
        <DisplayNews />
        <CRow>
          <CCol className="d-flex justify-content-between pb-4">
            <div className="d-flex flex-row">
              <h2>Produkty</h2>
            </div>
            <div>
              <EditButton
                label="+ Dodaj produkt"
                onClick={() => {
                  navigate('/produkt/new')
                }}
                lg
              />
            </div>
          </CCol>
        </CRow>

        <CRow>
          <CCol lg={3} className="align-self-start">
            <CCard className="overflow-hidden px-3 py-3 fill h-100">
              <CForm>
                <CRow className="ps-2">
                  <CFormLabel className="mb-3 px-0" htmlFor="name">
                    Nazwa produktu:
                  </CFormLabel>
                </CRow>
                <CRow className="px-2 mb-3">
                  <CFormInput type="text" name="name" {...register('name')}></CFormInput>
                </CRow>

                <CRow className="ps-2">
                  <CFormLabel className="mb-3 px-0" htmlFor="amountFrom">
                    Na stanie (od-do):
                  </CFormLabel>
                </CRow>
                <CRow className="px-0" xs={{ gutterX: 3 }}>
                  <CCol xs={6} className="px-1">
                    <CFormInput
                      type="number"
                      name="amountFrom"
                      {...register('amountFrom')}
                      min={0}
                    ></CFormInput>
                  </CCol>
                  <CCol xs={6} className="px-1">
                    <CFormInput
                      type="number"
                      name="amountTo"
                      {...register('amountTo')}
                      min={0}
                    ></CFormInput>
                  </CCol>
                </CRow>
                <CRow className="ps-2">
                  <CFormLabel className="mb-3 mt-3 px-0" htmlFor="meanPriceFrom">
                    Cena zakupu (od-do):
                  </CFormLabel>
                </CRow>
                <CRow className="px-0" xs={{ gutterX: 3 }}>
                  <CCol xs={6} className="px-1">
                    <CFormInput
                      type="number"
                      name="meanPriceFrom"
                      {...register('meanPriceFrom')}
                      min={0}
                    ></CFormInput>
                  </CCol>
                  <CCol xs={6} className="px-1">
                    <CFormInput
                      type="number"
                      name="meanPriceTo"
                      {...register('meanPriceTo')}
                      min={0}
                    ></CFormInput>
                  </CCol>
                </CRow>
                <CRow className="ps-2">
                  <CFormLabel className="mb-3 mt-3 px-0" htmlFor="valueFrom">
                    Wartość produktu (od-do):
                  </CFormLabel>
                </CRow>
                <CRow className="px-0" xs={{ gutterX: 3 }}>
                  <CCol xs={6} className="px-1">
                    <CFormInput
                      type="number"
                      name="totalFrom"
                      {...register('valueFrom')}
                      min={0}
                    ></CFormInput>
                  </CCol>
                  <CCol xs={6} className="px-1">
                    <CFormInput
                      type="number"
                      name="totalTo"
                      {...register('valueTo')}
                      min={0}
                    ></CFormInput>
                  </CCol>
                </CRow>
              </CForm>
            </CCard>
          </CCol>
          <CCol lg={9}>
            <CCard className="shadow-none">
              <CCardHeader>
                <CNav variant="tabs" className="card-header-tabs">
                  <CNavItem>
                    <CNavLink
                      href="#"
                      active={activeKey === 1}
                      onClick={() => {
                        setType('')
                        // setValue('type', '')
                        setActiveKey(1)
                      }}
                      className="fw-semibold"
                    >
                      WSZYSTKIE
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      href="#"
                      active={activeKey === 2}
                      onClick={() => {
                        setType('newest')
                        // setValue('type', 'newest')
                        setActiveKey(2)
                      }}
                      className="fw-semibold"
                    >
                      NOWOŚCI
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      href="#"
                      active={activeKey === 3}
                      onClick={() => {
                        setType('nearOutOfStock')
                        // setValue('type', 'nearOutOfStock')
                        setActiveKey(3)
                      }}
                      className="fw-semibold"
                    >
                      NA WYCZERPANIU
                    </CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink
                      href="#"
                      active={activeKey === 4}
                      onClick={() => {
                        setType('outOfStock')
                        // setValue('type', 'outOfStock')
                        setActiveKey(4)
                      }}
                      className="fw-semibold"
                    >
                      BRAKI MAGAZYNOWE
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CCardHeader>
              <CCardBody>
                <CTabContent>
                  {isPreviousData && <IsLoadingOverlay />}
                  <CTabPane role="tabpanel" aria-labelledby="orders" visible={activeKey === 1}>
                    {isAdmin && <StoreValue type={type} />}
                    {data && (
                      <CSmartTable
                        loading={isLoading}
                        items={data.data.results}
                        itemsPerPageSelect
                        itemsPerPage={itemsPerPage}
                        pagination={{ external: true }}
                        paginationProps={{
                          activePage: activePage,
                          pages: data.data.pages || 1,
                          ...paginationProps,
                        }}
                        onActivePageChange={setActivePage}
                        onItemsPerPageChange={(value) => {
                          setActivePage(1)
                          setItemsPerPage(value)
                        }}
                        onSorterChange={(value) => {
                          setSortBy(value.column)
                          setSortDir(value.state)
                        }}
                        {...tableProps(navigate, isAdmin)}
                      />
                    )}
                  </CTabPane>
                  <CTabPane role="tabpanel" aria-labelledby="zamówienia" visible={activeKey === 2}>
                    {isAdmin && <StoreValue type={type} />}
                    {data && (
                      <CSmartTable
                        loading={isLoading}
                        items={data.data.results}
                        itemsPerPage={itemsPerPage}
                        pagination={{ external: true }}
                        paginationProps={{
                          activePage: activePage,
                          pages: data.data.pages || 1,
                          ...paginationProps,
                        }}
                        onActivePageChange={setActivePage}
                        onItemsPerPageChange={(value) => {
                          setActivePage(1)
                          setItemsPerPage(value)
                        }}
                        onSorterChange={(value) => {
                          setSortBy(value.column)
                          setSortDir(value.state)
                        }}
                        {...tableProps(navigate, isAdmin)}
                      />
                    )}
                  </CTabPane>
                  <CTabPane role="tabpanel" aria-labelledby="zamówienia" visible={activeKey === 3}>
                    {isAdmin && <StoreValue type={type} />}
                    {data && (
                      <CSmartTable
                        loading={isLoading}
                        items={data.data.results}
                        itemsPerPage={itemsPerPage}
                        pagination={{ external: true }}
                        paginationProps={{
                          activePage: activePage,
                          pages: data.data.pages || 1,
                          ...paginationProps,
                        }}
                        onActivePageChange={setActivePage}
                        onItemsPerPageChange={(value) => {
                          setActivePage(1)
                          setItemsPerPage(value)
                        }}
                        onSorterChange={(value) => {
                          setSortBy(value.column)
                          setSortDir(value.state)
                        }}
                        {...tableProps(navigate, isAdmin)}
                      />
                    )}
                  </CTabPane>
                  <CTabPane role="tabpanel" aria-labelledby="zamówienia" visible={activeKey === 4}>
                    {isAdmin && <StoreValue type={type} />}
                    {data && (
                      <CSmartTable
                        loading={isLoading}
                        items={data.data.results}
                        itemsPerPageSelect
                        itemsPerPage={itemsPerPage}
                        pagination={{ external: true }}
                        paginationProps={{
                          activePage: activePage,
                          pages: data.data.pages || 1,
                          ...paginationProps,
                        }}
                        onActivePageChange={setActivePage}
                        onItemsPerPageChange={(value) => {
                          setActivePage(1)
                          setItemsPerPage(value)
                        }}
                        onSorterChange={(value) => {
                          setSortBy(value.column)
                          setSortDir(value.state)
                        }}
                        {...tableProps(navigate, isAdmin)}
                      />
                    )}
                  </CTabPane>
                </CTabContent>
              </CCardBody>
              {isAdmin && (
                <div className="px-3 mb-3 w-full d-flex flex-row justify-content-end">
                  <p className="text-primary btn shadow-none" onClick={() => handleDownloadCSV()}>
                    pobierz .csv widoku
                  </p>
                </div>
              )}
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </>
  )
}

export default Products
